import { ACTIONS } from "./../constants"


const initialState = { 
  isLoading: false, 
  success: false, 
  error: null,
  promotions: [],
  currentPromotion: null,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  isFetching: false,
}


const promotionReducer = (state = initialState, action) => {
  
  const { payload, type } = action
  
  switch (type) {

    case ACTIONS.CLEAR_ERROR : {
      return { ...state, ...initialState }
    }
    
    case ACTIONS.UPDATE_PROMOTION_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.UPDATE_PROMOTION_SUCCEDED : {
      return { ...state, isLoading: false, success: true, error: null }
    }
    case ACTIONS.UPDATE_PROMOTION_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }


    case ACTIONS.CREATE_PROMOTION_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.CREATE_PROMOTION_SUCCEDED : {
      return { ...state, isLoading: false, success: true, error: null }
    }
    case ACTIONS.CREATE_PROMOTION_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }


    case ACTIONS.DELETE_PROMOTION_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.DELETE_PROMOTION_SUCCEDED : {
      return { ...state, isLoading: false, success: true, error: null }
    }
    case ACTIONS.DELETE_PROMOTION_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }

    case ACTIONS.FETCH_CURRENT_PROMOTION_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.FETCH_CURRENT_PROMOTION_SUCCEDED : {
      return { ...state, isLoading: false, currentPromotion: payload, error: null }
    }
    case ACTIONS.FETCH_CURRENT_PROMOTION_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }


    case ACTIONS.FETCH_PROMOTIONS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_PROMOTIONS_SUCCEDED : {
      const { results = [], totalItems = 0, totalPages = 1, currentPage = 1 } = payload
      return {
        ...state,
        isFetching: false,
        promotions: results,
        totalItems,
        currentPage,
        totalPages
      }
    }
    case ACTIONS.FETCH_PROMOTIONS_FAILED : {
      return { ...state, isFetching: false, hasMore: false, error: payload }
    }
   
    default: {
      return state
    }
  }
}


export default promotionReducer
