import { ACTIONS } from "./../constants"

const initialState = {
    isLoading: false,
    success: false,
    error: null,
    topArticles: [],
}

const topArticlesReducer = (state = initialState, action) => {
    const { payload, type } = action

    switch (type) {
        case ACTIONS.FETCH_TOP_ARTICLES_INIT: {
            return { ...state, isFetching: true, error: null }
        }
        case ACTIONS.FETCH_TOP_ARTICLES_SUCCEDED: {
            return {
                ...state,
                isFetching: false,
                topArticles: payload
            }
        }
        case ACTIONS.FETCH_TOP_ARTICLES_FAILED: {
            return { ...state, isFetching: false, hasMore: false, error: payload }
        }
        default: {
            return state
        }
    }
}

export default topArticlesReducer