import { ACTIONS } from "./../constants"


const initialState = { 
  isLoading: false,
  success: false, 
  error: null,
  messages: [],
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  isFetching: false,
}


const flashMessageReducer = (state = initialState, action) => {
  
  const { payload, type } = action
  
  switch (type) {

    case ACTIONS.CLEAR_ERROR : {
      return { ...state, ...initialState }
    }
    case ACTIONS.INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.FAILED : {
      return { ...state, isLoading: false, error: payload, success: false }
    }
   
    default: {
      return state
    }
  }
}


export default flashMessageReducer
