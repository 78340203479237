import { ACTIONS } from "./../constants"


const initialState = { 
  user: null,
  invoices: [],
  nextPayments: [],
  orders: [],
  userDashboard: {},
  storeDashboard: {},
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  isFetching: false, 
  isLoading: false,
  success: false, 
  error: null 
}


const userReducer = (state = initialState, action) => {
  
  const { payload, type } = action
  
  switch (type) {

    case ACTIONS.CLEAR_ERROR : {
      return { ...initialState }
    }

    case ACTIONS.LOGOUT_SUCCEDED : {
      return { ...initialState }
    }
    
    case ACTIONS.FETCH_USER_INFORMATION_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_USER_INFORMATION_SUCCEDED : {
      return { ...state, isFetching: false, user: payload }
    }
    case ACTIONS.FETCH_USER_INFORMATION_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.FETCH_USER_ORDERS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_USER_ORDERS_SUCCEDED : {
      const { results = [], totalItems = 0, totalPages = 1, currentPage = 1 } = payload
      return { ...state, isFetching: false, orders: results, totalItems, totalPages, currentPage}
    }
    case ACTIONS.FETCH_USER_ORDERS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }


    case ACTIONS.FETCH_INVOICES_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_INVOICES_SUCCEDED : {
      return { ...state, invoices: payload}
    }
    case ACTIONS.FETCH_INVOICES_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }


    case ACTIONS.FETCH_UNSOLD_INVOICES_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_UNSOLD_INVOICES_SUCCEDED : {
      return { ...state, nextPayments: payload}
    }
    case ACTIONS.FETCH_UNSOLD_INVOICES_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.UPDATE_USER_INFORMATION_INIT : {
      return { ...state, isFetching: true, success: false, error: null }
    }
    case ACTIONS.UPDATE_USER_INFORMATION_SUCCEDED : {
      return { ...state, isFetching: false, success: true, user: payload }
    }
    case ACTIONS.UPDATE_USER_INFORMATION_FAILED : {
      return { ...state, isFetching: false, success: false, error: payload }
    }


    case ACTIONS.FETCH_USER_DASHBOARD_INFORMATION_INIT : {
      return { ...state, isFetching: true, success: false, error: null }
    }
    case ACTIONS.FETCH_USER_DASHBOARD_INFORMATION_SUCCEDED : {
      return { ...state, isFetching: false, success: true, userDashboard: payload }
    }
    case ACTIONS.FETCH_USER_DASHBOARD_INFORMATION_FAILED : {
      return { ...state, isFetching: false, success: false, error: payload }
    }

    case ACTIONS.FETCH_STORE_DASHBOARD_INFORMATION_INIT : {
      return { ...state, isFetching: true, success: false, error: null }
    }
    case ACTIONS.FETCH_STORE_DASHBOARD_INFORMATION_SUCCEDED : {
      return { ...state, isFetching: false, success: true, storeDashboard: payload }
    }
    case ACTIONS.FETCH_STORE_DASHBOARD_INFORMATION_FAILED : {
      return { ...state, isFetching: false, success: false, error: payload }
    }
   
    default: {
      return state
    }
  }
}


export default userReducer
