import { ACTIONS } from "./../constants"


const initialState = { 
  success: false, 
  error: null,
  partners: [],
  isFetching: false,
}


const partnerReducer = (state = initialState, action) => {
  
  const { payload, type } = action
  
  switch (type) {

    case ACTIONS.CLEAR_ERROR : {
      return { ...state, ...initialState }
    }
    
    case ACTIONS.FETCH_PARTNERS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_PARTNERS_SUCCEDED : {
      return {
        ...state,
        isFetching: false,
        partners: payload
      }
    }
    case ACTIONS.FETCH_PARTNERS_FAILED : {
      return { ...state, isFetching: false, hasMore: false, error: payload }
    }
   
    default: {
      return state
    }
  }
}


export default partnerReducer
