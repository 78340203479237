import { ACTIONS } from "../constants"

const initialState = {
  isLoading: false,
  success: false,
  error: null,
  topUsers: [],
}

const orderMonthReducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case ACTIONS.FETCH_ORDERS_CURRENT_MONTH_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_ORDERS_CURRENT_MONTH_SUCCEDED: {
      return {
        ...state,
        ordersCurrentMonth: payload,
        isLoaded: true,
      }
    }
    case ACTIONS.FETCH_ORDERS_CURRENT_MONTH_FAILED: {
      return { ...state, isFetching: false, hasMore: false, error: payload }
    }
    default: {
      return state
    }
  }
}

export default orderMonthReducer