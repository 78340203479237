import { ACTIONS } from "../constants"

const initialState = {
  isLoading: false,
  success: false,
  error: null,
  topAllUsers: [],
}

const topAllUsersReducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case ACTIONS.FETCH_TOP_ALL_USERS_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_TOP_ALL_USERS_SUCCEDED: {
      return {
        ...state,
        topAllUsers: payload,
        isLoaded: true,
      }
    }
    case ACTIONS.FETCH_TOP_ALL_USERS_FAILED: {
      return { ...state, isFetching: false, hasMore: false, error: payload }
    }
    default: {
      return state
    }
  }
}

export default topAllUsersReducer