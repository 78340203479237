import { ACTIONS } from "../constants"

const initialState = {
    isLoading: false,
    success: false,
    error: null,
    topAllArticles: [],
}

const topAllArticlesReducer = (state = initialState, action) => {
    const { payload, type } = action

    switch (type) {
        case ACTIONS.FETCH_TOP_ALL_ARTICLES_INIT: {
            return { ...state, isFetching: true, error: null }
        }
        case ACTIONS.FETCH_TOP_ALL_ARTICLES_SUCCEDED: {
            return {
                ...state,
                isFetching: false,
                topAllArticles: payload
            }
        }
        case ACTIONS.FETCH_TOP_ALL_ARTICLES_FAILED: {
            return { ...state, isFetching: false, hasMore: false, error: payload }
        }
        default: {
            return state
        }
    }
}

export default topAllArticlesReducer