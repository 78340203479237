import axios from 'axios'
import { getEnv } from '.'


export class APIRequest {

  constructor(baseURL){
    this.client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  sendRequest = (method, url, data = {}, headers = {}, params = {}) =>  this.client.request({
    method,
    data,
    url,
    headers,
    params
  })

}

export const makeCall = (method, url, data = {}, headers = {}, params = {}, extra = {}) => {

  const client = axios.create({
    baseURL: getEnv("REACT_APP_API_BASE_URL"),
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    }
  })

  return client.request({
    method,
    data,
    url,
    headers,
    params,
    ...extra
  })
}
