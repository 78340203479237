import { ACTIONS } from "./../constants"


const initialState = { 
  isLoading: false, 
  success: false, 
  error: null,
  latestArrivals: [],
  latestArrival: {},
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  isFetching: false,
}


const latestArrivalReducer = (state = initialState, action) => {
  
  const { payload, type } = action
  
  switch (type) {

    case ACTIONS.CLEAR_ERROR : {
      return { ...state, ...initialState }
    }
    
    case ACTIONS.UPDATE_LATEST_ARRIVAL_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.UPDATE_LATEST_ARRIVAL_SUCCEDED : {
      return { ...state, isLoading: false, success: true, error: null }
    }
    case ACTIONS.UPDATE_LATEST_ARRIVAL_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }


    case ACTIONS.CREATE_LATEST_ARRIVAL_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.CREATE_LATEST_ARRIVAL_SUCCEDED : {
      return { ...state, isLoading: false, success: true, error: null }
    }
    case ACTIONS.CREATE_LATEST_ARRIVAL_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }

    case ACTIONS.FETCH_CURRENT_ARRIVALS_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.FETCH_CURRENT_ARRIVALS_SUCCEDED : {
      return { ...state, isLoading: false, latestArrival: payload, error: null }
    }
    case ACTIONS.FETCH_CURRENT_ARRIVALS_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }


    case ACTIONS.DELETE_LATEST_ARRIVAL_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.DELETE_LATEST_ARRIVAL_SUCCEDED : {
      return { ...state, isLoading: false, success: true, error: null }
    }
    case ACTIONS.DELETE_LATEST_ARRIVAL_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }


    case ACTIONS.FETCH_LATEST_ARRIVALS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_LATEST_ARRIVALS_SUCCEDED : {
      const { results = [], totalItems = 0, totalPages = 1, currentPage = 1 } = payload
      return {
        ...state,
        isFetching: false,
        latestArrivals: results,
        totalItems,
        currentPage,
        totalPages
      }
    }
    case ACTIONS.FETCH_LATEST_ARRIVALS_FAILED : {
      return { ...state, isFetching: false, hasMore: false, error: payload }
    }
   
    default: {
      return state
    }
  }
}


export default latestArrivalReducer
