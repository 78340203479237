// auth
export const LOGIN = "/api/auth/login"
export const CHECK_LOGIN = "/api/auth/login-check"
export const LOGOUT = "/api/auth/logout"
export const FORGOT_PASSWORD = "/api/auth/reset-request"
export const RESET_PASSWORD = "/api/auth/reset"
export const FETCH_TOKEN = "/api/auth/reset-token/:param"
export const REGISTER = "/api/auth/register"

// contact
export const CONTACT_US = "/api/contact-us"

// user
export const ME = "/api/me"
export const MY_DASHBOARD = "/api/dashboard/client"
export const STORE_DASHBOARD = "/api/dashboard/store"
export const TOP_ARTICLES = "/api/dashboardv2/top-articles"
export const TOP_ALL_ARTICLES = "/api/dashboardv2/top-allarticles"
export const TOP_USERS = "/api/dashboardv2/top-users"
export const TOP_ALL_USERS = "/api/dashboardv2/top-allusers"
export const ACTIVE_USERS = "/api/dashboardv2/list-active-users"
export const REVENUE_PER_MONTH = "/api/dashboardv2/revenue-per-month"
export const ORDERS_CURRENT_MONTH = "/api/dashboardv2/orders_current_month"

export const FETCH_PRODUCTS = "/api/items"
export const SIMILAR_PRODUCTS = "/api/items/:param"
export const FETCH_MESSAGES = "/api/messages"
export const FETCH_CUSTOMERS = "/api/customers"
export const CUSTOMER = "/api/customers/:param"
export const ORDERS = "/api/orders"
export const ORDER = "/api/orders/:param/article/:storeParam"

export const PROMOTIONS = "/api/promotions"
export const PROMOTION = "/api/promotions/:param"
export const CURRENT_PROMOTION = "/api/promotions/now"

export const LATEST_ARRIVALS = "/api/latest-arrivals"
export const CURRENT_ARRIVALS = "/api/latest-arrivals/now"
export const LATEST_ARRIVAL = "/api/latest-arrivals/:param"

export const CHECKOUT_ORDER = "/api/orders"
export const MY_ORDERS = "/api/orders/me"

export const MY_INVOICES = "/api/timeline"
export const MY_UNSOLD_INVOICES = "/api/unsolded-invoices"

export const FETCH_PARTNERS = "/api/partners"
export const DELETE_PARTNER = "/api/partners/:param"
export const SORT_PARTNER = "/api/partners/:from/:to"
export const UPLOAD_PARTNER = "/api/partners"

export const FETCH_MARKS = "/api/marks"
export const EDIT_MARK = "/api/marks/:param"
export const DELETE_MARK = "/api/marks/:param"
export const SORT_MARK = "/api/marks/:from/:to"
export const UPLOAD_MARK = "/api/marks"

export const FETCH_MARKS_PARTNERS = "/api/marks-partners"