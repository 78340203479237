import { ACTIONS } from "./../constants"

const initialState = {
  isLoading: false,
  success: false,
  error: null,
  activeCSV: [] // Rename usersActive to activeCSV
};

const activeUsersReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.FETCH_ACTIVE_USERS_INIT:
      return { ...state, isLoading: true, error: null };
    case ACTIONS.FETCH_ACTIVE_USERS_SUCCEDED:
      return { ...state, activeCSV: payload, isLoading: false, success: true };
    case ACTIONS.FETCH_ACTIVE_USERS_FAILED:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
};

export default activeUsersReducer