import { CALL_API, API_BASE_URL, HTTP_METHODS } from './../constants'
import { APIRequest, objectToFormData } from './../../utils'
import { PURGE } from "redux-persist"
import {FAILED, INIT, SUCCEDED} from '../constants/actionsConstant'


const client = new APIRequest(API_BASE_URL)


const api = store => next => async action => {
  if (!action || action.type !== CALL_API ){
    return next(action)
  }

  let { payload } = action
  const { actions, endpoint, method, isFormData, params = {}, extraData={} } = action.meta

  console.log(params)
  const { token } = store.getState().authentication || {}

  console.log(token)

  const dispatch = dispatchActions(next)

  if (actions.init){
    dispatch(actions.init, params)
    dispatch(INIT, params)
  }

  let headers = {}
  if (token && token.access_token) {
    headers = { Authorization: `Bearer ${token.access_token}`}
  }

  // check for form data
  if (isFormData) {
    headers['Content-Type'] = 'multipart/form-data'
    payload = objectToFormData(action.payload)
  }

  client.sendRequest(method, endpoint, payload, headers, params)
    .then(resp => {
      dispatch(actions.success, resp.data, extraData)
      if (method === HTTP_METHODS.POST || method === HTTP_METHODS.PATCH || method === HTTP_METHODS.PUT || method === HTTP_METHODS.DELETE) {
        dispatch(SUCCEDED, resp.data, extraData)
      }
    })
    .catch(err => {
      dispatch(actions.fail, err.response || {})
      dispatch(FAILED, err.response || {})
      if (err.response && err.response.status === 401){
        next({
          type: PURGE,
          result: () => null
        })
      }else {
      }
    })
}


const dispatchActions = next => (action, payload = {}) => {
  if (!Array.isArray(action)) return next({type: action, payload})
  return action.map(a =>  next({type: a, payload}));
}

export default api
