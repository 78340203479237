export const CLEAR_ERROR = "common@CLEAR_ERROR"

export const INIT = "common@INIT"
export const FAILED = "common@FAILED"
export const SUCCEDED = "common@SUCCEDED"

// authentication
export const LOGIN_INIT = "authentication@LOGIN_INIT"
export const LOGIN_SUCCEDED =  "authentication@LOGIN_SUCCEDED"
export const LOGIN_FAILED = "authentication@LOGIN_FAILED"

export const CHECK_LOGIN_INIT = "authentication@CHECK_LOGIN_INIT"
export const CHECK_LOGIN_SUCCEDED =  "authentication@CHECK_LOGIN_SUCCEDED"
export const CHECK_LOGIN_FAILED = "authentication@CHECK_LOGIN_FAILED"

export const SIGNUP_INIT = "authentication@REGISTER_INIT"
export const SIGNUP_SUCCEDED = "authentication@REGISTER_SUCCEDED"
export const SIGNUP_FAILED = "authentication@REGISTER_FAILED"

export const FORGOT_PASSWORD_INIT = "authentication@FORGOT_PASSWORD_INIT"
export const FORGOT_PASSWORD_SUCCEDED = "authentication@FORGOT_PASSWORD_SUCCEDED"
export const FORGOT_PASSWORD_FAILED = "authentication@FORGOT_PASSWORD_FAILED"

export const FETCH_TOKEN_INIT = "authentication@FETCH_TOKEN_INIT"
export const FETCH_TOKEN_SUCCEDED = "authentication@FETCH_TOKEN_SUCCEDED"
export const FETCH_TOKEN_FAILED = "authentication@FETCH_TOKEN_FAILED"

export const UPDATE_PERSONAL_INFORMATION_SUCCEDED = "profile@UPDATE_PERSONAL_INFORMATION_SUCCEDED"

export const RESET_PASSWORD_INIT = "authentication@RESET_PASSWORD_INIT"
export const RESET_PASSWORD_SUCCEDED = "authentication@RESET_PASSWORD_SUCCEDED"
export const RESET_PASSWORD_FAILED = "authentication@RESET_PASSWORD_FAILED"

export const LOGOUT_INIT =  "authentication@LOGOUT_INIT"
export const LOGOUT_SUCCEDED = "authentication@LOGOUT_SUCCEDED"
export const LOGOUT_FAILED = "authentication@LOGOUT_FAILED"

// contact
export const CONTACT_US_INIT = "contactus@CONTACT_US_INIT"
export const CONTACT_US_SUCCEDED =  "contactus@CONTACT_US_SUCCEDED"
export const CONTACT_US_FAILED = "contactus@CONTACT_US_FAILED"

export const FETCH_MESSAGES_INIT = "contactus@MESSAGES_INIT"
export const FETCH_MESSAGES_SUCCEDED =  "contactus@FETCH_MESSAGES_SUCCEDED"
export const FETCH_MESSAGES_FAILED = "contactus@FETCH_MESSAGES_FAILED"

export const FETCH_ORDERS_INIT = "order@ORDERS_INIT"
export const FETCH_ORDERS_SUCCEDED =  "order@FETCH_ORDERS_SUCCEDED"
export const FETCH_ORDERS_FAILED = "order@FETCH_ORDERS_FAILED"

export const FETCH_INVOICES_INIT = "order@ORDER_INVOICES_INIT"
export const FETCH_INVOICES_SUCCEDED =  "order@FETCH_INVOICES_SUCCEDED"
export const FETCH_INVOICES_FAILED = "order@FETCH_INVOICES_FAILED"

export const FETCH_UNSOLD_INVOICES_INIT = "order@ORDER_UNSOLD_INVOICES_INIT"
export const FETCH_UNSOLD_INVOICES_SUCCEDED =  "order@FETCH_UNSOLD_INVOICES_SUCCEDED"
export const FETCH_UNSOLD_INVOICES_FAILED = "order@FETCH_UNSOLD_INVOICES_FAILED"

export const UPDATE_ORDER_INIT = "order@UPDATE_ORDER_INIT"
export const UPDATE_ORDER_SUCCEDED =  "order@UPDATE_ORDER_SUCCEDED"
export const UPDATE_ORDER_FAILED = "order@UPDATE_ORDER_FAILED"

export const FETCH_CUSTOMERS_INIT = "customer@CUSTOMERS_INIT"
export const FETCH_CUSTOMERS_SUCCEDED =  "customer@FETCH_CUSTOMERS_SUCCEDED"
export const FETCH_CUSTOMERS_FAILED = "customer@FETCH_CUSTOMERS_FAILED"

export const UPDATE_CUSTOMER_INIT = "order@UPDATE_CUSTOMER_INIT"
export const UPDATE_CUSTOMER_SUCCEDED =  "order@UPDATE_CUSTOMER_SUCCEDED"
export const UPDATE_CUSTOMER_FAILED = "order@UPDATE_CUSTOMER_FAILED"

// cart
export const ADD_TO_CART = "cart@ADD_TO_CART"
export const REMOVE_FROM_CART = "cart@REMOVE_FROM_CART"
export const CLEAR_THE_CART = "cart@CLEAR_THE_CART"
export const ADD_QUANTITY = "cart@ADD_QUANTITY"
export const SUBTRACT_QUANTITY = "cart@SUBTRACT_QUANTITY"

// user account
export const FETCH_USER_INFORMATION_INIT = "user@FETCH_USER_INFORMATION_INIT"
export const FETCH_USER_INFORMATION_SUCCEDED = "user@FETCH_USER_INFORMATION_SUCCEDED"
export const FETCH_USER_INFORMATION_FAILED = "user@FETCH_USER_INFORMATION_FAILED"

export const FETCH_USER_DASHBOARD_INFORMATION_INIT = "user@FETCH_USER_DASHBOARD_INFORMATION_INIT"
export const FETCH_USER_DASHBOARD_INFORMATION_SUCCEDED = "user@FETCH_USER_DASHBOARD_INFORMATION_SUCCEDED"
export const FETCH_USER_DASHBOARD_INFORMATION_FAILED = "user@FETCH_USER_DASHBOARD_INFORMATION_FAILED"

export const FETCH_STORE_DASHBOARD_INFORMATION_INIT = "user@FETCH_STORE_DASHBOARD_INFORMATION_INIT"
export const FETCH_STORE_DASHBOARD_INFORMATION_SUCCEDED = "user@FETCH_STORE_DASHBOARD_INFORMATION_SUCCEDED"
export const FETCH_STORE_DASHBOARD_INFORMATION_FAILED = "user@FETCH_STORE_DASHBOARD_INFORMATION_FAILED"

export const FETCH_USER_ORDERS_INIT = "user@FETCH_USER_ORDERS_INIT"
export const FETCH_USER_ORDERS_SUCCEDED = "user@FETCH_USER_ORDERS_SUCCEDED"
export const FETCH_USER_ORDERS_FAILED = "user@FETCH_USER_ORDERS_FAILED"

export const UPDATE_USER_INFORMATION_INIT = "user@UPDATE_USER_INFORMATION_INIT"
export const UPDATE_USER_INFORMATION_SUCCEDED = "user@UPDATE_USER_INFORMATION_SUCCEDED"
export const UPDATE_USER_INFORMATION_FAILED = "user@UPDATE_USER_INFORMATION_FAILED"

// products
export const FETCH_PARTNERS_INIT = "partner@FETCH_PARTNERS_INIT"
export const FETCH_PARTNERS_SUCCEDED = "partner@FETCH_PARTNERS_SUCCEDED"
export const FETCH_PARTNERS_FAILED = "partner@FETCH_PARTNERS_FAILED"

export const FETCH_CURRENT_ARRIVALS_INIT = "latestArrival@FETCH_CURRENT_ARRIVALS_INIT"
export const FETCH_CURRENT_ARRIVALS_SUCCEDED = "latestArrival@FETCH_CURRENT_ARRIVALS_SUCCEDED"
export const FETCH_CURRENT_ARRIVALS_FAILED = "latestArrival@FETCH_CURRENT_ARRIVALS_FAILED"

export const FETCH_PRODUCTS_INIT = "product@FETCH_PRODUCTS_INIT"
export const FETCH_PRODUCTS_SUCCEDED = "products@FETCH_PRODUCTS_SUCCEDED"
export const FETCH_PRODUCTS_FAILED = "products@FETCH_PRODUCTS_FAILED"

export const FETCH_SIMILAR_PRODUCTS_INIT = "product@FETCH_SIMILAR_PRODUCTS_INIT"
export const FETCH_SIMILAR_PRODUCTS_SUCCEDED = "products@FETCH_SIMILAR_PRODUCTS_SUCCEDED"
export const FETCH_SIMILAR_PRODUCTS_FAILED = "products@FETCH_SIMILAR_PRODUCTS_FAILED"


export const FILTER_PRODUCTS = "products@FILTER_PRODUCTS"


export const FETCH_PRODUCT_INIT = "product@FETCH_PRODUCT_INIT"
export const FETCH_PRODUCT_SUCCEDED = "products@FETCH_PRODUCT_SUCCEDED"
export const FETCH_PRODUCT_FAILED = "products@FETCH_PRODUCT_FAILED"

export const FETCH_PROMOTIONS_INIT = "promotion@FETCH_PROMOTIONS_INIT"
export const FETCH_PROMOTIONS_SUCCEDED = "promotion@FETCH_PROMOTIONS_SUCCEDED"
export const FETCH_PROMOTIONS_FAILED = "promotion@FETCH_PROMOTIONS_FAILED"

export const FETCH_MARKS_INIT = "promotion@FETCH_MARKS_INIT"
export const FETCH_MARKS_SUCCEDED = "promotion@FETCH_MARKS_SUCCEDED"
export const FETCH_MARKS_FAILED = "promotion@FETCH_MARKS_FAILED"

export const FETCH_CURRENT_PROMOTION_INIT = "promotion@FETCH_CURRENT_PROMOTION_INIT"
export const FETCH_CURRENT_PROMOTION_SUCCEDED = "promotion@FETCH_CURRENT_PROMOTION_SUCCEDED"
export const FETCH_CURRENT_PROMOTION_FAILED = "promotion@FETCH_CURRENT_PROMOTION_FAILED"

export const UPDATE_PROMOTION_INIT = "promotion@UPDATE_PROMOTION_INIT"
export const UPDATE_PROMOTION_SUCCEDED = "promotion@UPDATE_PROMOTION_SUCCEDED"
export const UPDATE_PROMOTION_FAILED = "promotion@UPDATE_PROMOTION_FAILED"

export const DELETE_PROMOTION_INIT = "promotion@DELETE_PROMOTION_INIT"
export const DELETE_PROMOTION_SUCCEDED = "promotion@DELETE_PROMOTION_SUCCEDED"
export const DELETE_PROMOTION_FAILED = "promotion@DELETE_PROMOTION_FAILED"

export const CREATE_PROMOTION_INIT = "promotion@CREATE_PROMOTION_INIT"
export const CREATE_PROMOTION_SUCCEDED = "promotion@CREATE_PROMOTION_SUCCEDED"
export const CREATE_PROMOTION_FAILED = "promotion@CREATE_PROMOTION_FAILED"

export const CHECKOUT_ORDER_INIT = "checkout@CHECKOUT_ORDER_INIT"
export const CHECKOUT_ORDER_SUCCEDED = "checkout@CHECKOUT_ORDER_SUCCEDED"
export const CHECKOUT_ORDER_FAILED = "checkout@CHECKOUT_ORDER_FAILED"

export const FETCH_LATEST_ARRIVALS_INIT = "promotion@FETCH_LATEST_ARRIVALS_INIT"
export const FETCH_LATEST_ARRIVALS_SUCCEDED = "promotion@FETCH_LATEST_ARRIVALS_SUCCEDED"
export const FETCH_LATEST_ARRIVALS_FAILED = "promotion@FETCH_LATEST_ARRIVALS_FAILED"

export const UPDATE_LATEST_ARRIVAL_INIT = "promotion@UPDATE_LATEST_ARRIVAL_INIT"
export const UPDATE_LATEST_ARRIVAL_SUCCEDED = "promotion@UPDATE_LATEST_ARRIVAL_SUCCEDED"
export const UPDATE_LATEST_ARRIVAL_FAILED = "promotion@UPDATE_LATEST_ARRIVAL_FAILED"

export const DELETE_LATEST_ARRIVAL_INIT = "promotion@DELETE_LATEST_ARRIVAL_INIT"
export const DELETE_LATEST_ARRIVAL_SUCCEDED = "promotion@DELETE_LATEST_ARRIVAL_SUCCEDED"
export const DELETE_LATEST_ARRIVAL_FAILED = "promotion@DELETE_LATEST_ARRIVAL_FAILED"

export const CREATE_LATEST_ARRIVAL_INIT = "promotion@CREATE_LATEST_ARRIVAL_INIT"
export const CREATE_LATEST_ARRIVAL_SUCCEDED = "promotion@CREATE_LATEST_ARRIVAL_SUCCEDED"
export const CREATE_LATEST_ARRIVAL_FAILED = "promotion@CREATE_LATEST_ARRIVAL_FAILED"

// articles
export const FETCH_TOP_ARTICLES_INIT = "article@FETCH_TOP_ARTICLES_INIT"
export const FETCH_TOP_ARTICLES_SUCCEDED =  "article@FETCH_TOP_ARTICLES_SUCCEDED"
export const FETCH_TOP_ARTICLES_FAILED = "article@FETCH_TOP_ARTICLES_FAILED"

// users
export const FETCH_TOP_USERS_INIT = "user@FETCH_TOP_USERS_INIT"
export const FETCH_TOP_USERS_SUCCEDED =  "user@FETCH_TOP_USERS_SUCCEDED"
export const FETCH_TOP_USERS_FAILED = "user@FETCH_TOP_USERS_FAILED"

// articles
export const FETCH_TOP_ALL_ARTICLES_INIT = "article@FETCH_TOP_ALL_ARTICLES_INIT"
export const FETCH_TOP_ALL_ARTICLES_SUCCEDED =  "article@FETCH_TOP_ALL_ARTICLES_SUCCEDED"
export const FETCH_TOP_ALL_ARTICLES_FAILED = "article@FETCH_TOP_ALL_ARTICLES_FAILED"

// TOP USERS
export const FETCH_TOP_ALL_USERS_INIT = "user@FETCH_TOP_ALL_USERS_INIT"
export const FETCH_TOP_ALL_USERS_SUCCEDED =  "user@FETCH_TOP_ALL_USERS_SUCCEDED"
export const FETCH_TOP_ALL_USERS_FAILED = "user@FETCH_TOP_ALL_USERS_FAILED"

// Active Users
export const FETCH_ACTIVE_USERS_INIT = "user@FETCH_ACTIVE_USERS_INIT"
export const FETCH_ACTIVE_USERS_SUCCEDED =  "user@FETCH_ACTIVE_USERS_SUCCEDED"
export const FETCH_ACTIVE_USERS_FAILED = "user@FETCH_ACTIVE_USERS_FAILED"

// REVENUE PER MONTH
export const FETCH_REVENUE_PER_MONTH_INIT = "user@FETCH_REVENUE_PER_MONTH_INIT"
export const FETCH_REVENUE_PER_MONTH_SUCCEDED =  "user@FETCH_REVENUE_PER_MONTH_SUCCEDED"
export const FETCH_REVENUE_PER_MONTH_FAILED = "user@FETCH_REVENUE_PER_MONTH_FAILED"

// ORDER CURRENT MONTH
export const FETCH_ORDERS_CURRENT_MONTH_INIT = "user@FETCH_ORDERS_CURRENT_MONTH_INIT"
export const FETCH_ORDERS_CURRENT_MONTH_SUCCEDED =  "user@FETCH_ORDERS_CURRENT_MONTH_SUCCEDED"
export const FETCH_ORDERS_CURRENT_MONTH_FAILED = "user@FETCH_ORDERS_CURRENT_MONTH_FAILED"