import {combineReducers} from 'redux'

// thrid party lib reducers
import { reducer as formReducer } from 'redux-form'

// app reducers
import authenticationReducer from './authenticationReducer'
import contactReducer from './contactReducer'
import cartReducer from './cartReducer'
import productReducer from './productReducer'
import userReducer from './userReduer'
import flashMessageReducer from './flashMessageReducer'
import customerReducer from './customerReducer'
import orderReducer from './orderReducer'
import promotionReducer from './promotionReducer'
import latestArrivalReducer from './latestArrivalReducer'
import partnerReducer from './partnerReducer'
import markReducer from './markReducer'
import topArticlesReducer from './topArticlesReducer'
import topAllArticlesReducer from './topAllArticlesReducer'
import topUsersReducer from './topUsersReducer'
import topAllUsersReducer from './topAllUsersReducer'
import orderMonthReducer from './orderMonthReducer'
import revenueReducer from './revenueReducer'
import activeUsersReducer from './activeUsersReducer'

// combine app reducers
export default combineReducers({
  form: formReducer,

  authentication: authenticationReducer,
  mark: markReducer,
  contact: contactReducer,
  user: userReducer,
  latestArrival: latestArrivalReducer,
  customer: customerReducer,
  partner: partnerReducer,
  cart: cartReducer,
  promotion: promotionReducer,
  order: orderReducer,
  flashMessage: flashMessageReducer,
  product: productReducer,
  topAr: topArticlesReducer,
  topAllAr : topAllArticlesReducer,
  topUs: topUsersReducer,
  topAllUs: topAllUsersReducer,
  orderMonth : orderMonthReducer,
  revenuePerMonth : revenueReducer,
  activeUsersCSV : activeUsersReducer
})
