import { lazy } from 'react'

const PublicLayout = lazy(() =>  import('./../layouts/PublicLayout'))
const UserLayout = lazy(() =>  import('./../layouts/UserLayout'))
const AdminLayout = lazy(() =>  import('./../layouts/AdminLayout'))

export const adminSpace = {
  path: '/admin/',
  component: AdminLayout
}

export const clientSpace = {
  path: '/client',
  component: UserLayout
}

export const anonymousSpace = {
  path: '/',
  component: PublicLayout
}
