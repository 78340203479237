import { ACTIONS } from "./../constants"


const initialState = { 
  isLoading: false, 
  success: false, 
  error: null,
  messages: [],
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  isFetching: false,
}


const contactReducer = (state = initialState, action) => {
  
  const { payload, type } = action
  
  switch (type) {

    case ACTIONS.CLEAR_ERROR : {
      return { ...state, ...initialState }
    }
    
    case ACTIONS.CONTACT_US_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.CONTACT_US_SUCCEDED : {
      return { ...state, isLoading: false, success: true, error: null }
    }
    case ACTIONS.CONTACT_US_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }


    case ACTIONS.FETCH_MESSAGES_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_MESSAGES_SUCCEDED : {
      const { results = [], totalItems = 0, totalPages = 1, currentPage = 1 } = payload
      return {
        ...state,
        isFetching: false,
        messages: results,
        totalItems,
        currentPage,
        totalPages
      }
    }
    case ACTIONS.FETCH_MESSAGES_FAILED : {
      return { ...state, isFetching: false, hasMore: false, error: payload }
    }
   
    default: {
      return state
    }
  }
}


export default contactReducer
